import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/b7146c2/components/layout-content.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Terms of Service ("Terms")`}</h1>
    <p>{`Last updated: 03-03-2019`}</p>
    <p>{`Please read these Terms and Conditions ("Terms", "Terms and Conditions") carefully before using the
`}<a parentName="p" {...{
        "href": "https://components.ai"
      }}>{`https://components.ai`}</a>{` website operated by Components.ai ("us", "we", or "our"). Your access to and use
of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to
all visitors, users and others who access or use the Service. By accessing or using the Service you
agree to be bound by these Terms. If you disagree with any part of the terms then you may not access
the Service.`}</p>
    <h2>{`Links To Other Web Sites`}</h2>
    <p>{`Our Service may contain links to third­party web sites or services that are not owned or controlled by
Components.ai. Components.ai has no control over, and assumes no responsibility for, the content,
privacy policies, or practices of any third party web sites or services. You further acknowledge and
agree that Components.ai shall not be responsible or liable, directly or indirectly, for any damage or
loss caused or alleged to be caused by or in connection with use of or reliance on any such content,
goods or services available on or through any such web sites or services.`}</p>
    <h2>{`Changes`}</h2>
    <p>{`We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision
is material we will try to provide at least 30 days' notice prior to any new terms taking effect. What
constitutes a material change will be determined at our sole discretion.`}</p>
    <h2>{`Contact Us`}</h2>
    <p>{`If you have any questions about these Terms, please contact us at
`}<a parentName="p" {...{
        "href": "mailto:support@components.ai"
      }}>{`support@components.ai`}</a>{`.`}</p>
    <p>{`Copyright © 2018-2019 Components.ai. All rights reserved.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      